import { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { useWindowSize, INTERCOM_CONFIG, PATHS, COOKIES_CONFIG } from '@belong/common';
import { BREAKPOINTS_WIDTHS } from '@belong/common/dist/constants/breakpoints';
import { Toast } from '@belong/ui';
import { ChatWidget } from 'components/ChatWidget/ChatWidget';
import RouterHeaderNavLink from 'components/HeaderMain/RouterHeaderNavLink/RouterHeaderNavLink';
import MetaNoIndex from 'components/Metatags/MetaNoIndex';
import { getDefaultHomeownerGrowth } from 'consts/employee-assignments';
import { ContactUs } from 'containers/ContactUs/ContactUs';
import { find, findIndex, isNil, isEmpty } from 'es-toolkit/compat';
import { FlowLayoutV2 } from 'layouts/FlowLayout/FlowLayoutV2/FlowLayoutV2';
import Page from 'layouts/Page/Page';
import UserBasicInfo from 'models/common/UserBasicInfo';
import {
  EmployeeAssignmentTypes,
  AgreementFlowDataTypes,
  AgreementStatus,
  AgreementFlowStepNames as stepNames,
  StepStatus,
  OrderedAgreementStepEnums,
  StepUrlPaths,
} from 'models/enums';
import { parseCookies } from 'nookies';
import { getStepByName, STEPS_CONFIGURATION } from 'pages/AgreementFlow/consts';
import { getSaveAndExitPath } from 'pages/AgreementFlow/utils';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { BASE_PATHS } from 'routes/paths';
import {
  fetchAgreementFlowById,
  fetchAgreementFlowStep,
  updateAgreementFlowStep,
} from 'store/redux/agreement-flow/actions';
import { fetchAccountProperties, fetchAccountAgreements } from 'store/redux/homeowner-accounts/actions';
import { showModal } from 'store/redux/modals/actions';
import { fetchSettings } from 'store/redux/settings';
import { selectUser } from 'store/redux/user/selectors';
import { AGREEMENT_FLOW_STRINGS } from 'strings/agreement-flow.strings';
import { trackFullStoryEvent } from 'utils/tracking';
import { Analytics, PageViewTracker } from '../../analytics';
import { SCREENS } from '../../containercomponents/Modals/LoginModal/login-modal.consts';
import { MODALS } from '../../containercomponents/Modals/modal.consts';
import { STEPS_ROUTE_MAP } from './AgreementFlowSteps/Steps';
import SuccessPage from './AgreementFlowSteps/SuccessPage/SuccessPage';

export const getStepPath = (mappedStep, flowId) =>
  `${BASE_PATHS.AGREEMENT_FLOW}/${mappedStep.key}/${flowId}/${mappedStep.id}`;

// Animation have a fixed length, so we reset it when we move right enought.
const handleAddStep = (state) => (state + 1) % 9;

const AgreementFlow = ({
  match,
  history,
  location,
  user,
  fetchAgreementFlowByIdAction,
  fetchAgreementFlowStepAction,
  updateAgreementFlowStepAction,
  fetchAccountPropertiesAction,
  fetchAccountAgreementsAction,
  showModalAction,
  fetchSettingsAction,
}) => {
  const [loading, setLoading] = useState(true);
  const [employeeAssignmentPhone, setEmployeeAssignmentPhone] = useState({ link: null, display: null });
  const [setupFlow, setSetupFlow] = useState(null);
  const [settings, setSettings] = useState(null);
  const [mappedSteps, setMappedSteps] = useState([]);
  const [sidebarTip, setSidebarTip] = useState(null);
  const [makeGreen, setMakeGreen] = useState(false);
  const [currentAgreement, setCurrentAgreement] = useState(null);
  const [currentProperties, setCurrentProperties] = useState(null);
  const [isErrorToastVisible, setIsErrorToastVisible] = useState(false);
  const [errorText, setErrorText] = useState('');
  // Steps can disable it not animate the text change in tips
  const [animateTextChange, setAnimateTextChange] = useState(true);
  const { isEnabled } = INTERCOM_CONFIG.homeowner;
  // This controls the ballong animation on the sidebar, moves to the right on every next
  const [animatedStep, setAnimatedStep] = useReducer(handleAddStep, 0);
  const [currentStepState, setCurrentStepState] = useState({
    currentMappedStep: null,
    currentStepFormData: null,
  });
  const { width } = useWindowSize();
  const isMobile = width <= BREAKPOINTS_WIDTHS.MD;
  const cookies = parseCookies();

  const { stepId, step, flowId } = match?.params || {};
  const currentStepBasedStepId = find(mappedSteps, { id: stepId });

  // Copied from the reducer's logic since it's results are inconsistent for this flow.
  const isUserLoggedIn = !!user?.userId;

  const comeFromTheMobileApp = Boolean(cookies[COOKIES_CONFIG.MOBILE_APP.name] === 'true');

  const isComingFromEmailLink = () => {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.has('data') && step === 'plans';
  };

  const flowSource = isComingFromEmailLink() ? 'email' : comeFromTheMobileApp ? 'app' : 'placemat';

  const fullStoryEventMetadata = {
    source: flowSource,
    flowType: 'Invitation',
    flowSubType: 'Onboarding',
    stepEnum: currentStepBasedStepId?.key,
    stepDisplayName: currentStepBasedStepId?.displayName,
  };

  const handleErrorTracking = () => {
    trackFullStoryEvent({
      eventName: 'flow-error',
      metadata: fullStoryEventMetadata,
    });
  };

  const to = getSaveAndExitPath({ properties: currentProperties });

  const HeaderLinks = [
    <ContactUs key="ContactUs" disableChat />,
    <RouterHeaderNavLink key="save" label="SAVE & EXIT" to={to} />,
  ];

  const isCurrentUserPrimaryOwner = currentAgreement?.agreementUsers?.some(
    (au) => au?.agreementUser?.isAgreementFlowOwner
  );

  const navigateToSuccess = async ({ primaryOwnerName = '' } = {}) => {
    let agreements;

    try {
      agreements = await fetchAccountAgreementsAction();
    } catch (err) {
      handleErrorTracking();
      console.error(err);
    }

    const agreementStep = mappedSteps.find((ms) => ms.dataType === AgreementFlowDataTypes.Agreement);
    const currentAgreementObject = agreements?.find((a) => a?.agreement?.uniqueId === agreementStep.dataId);
    const agreementSigned = currentAgreementObject?.agreement?.status === AgreementStatus.Signed;
    setCurrentAgreement(currentAgreementObject);
    const currentProperty = currentProperties?.find((property) => {
      return currentAgreementObject?.homeAgreements?.find((homeAgreement) =>
        property.units.some((unit) => unit.basicInfo.unitId === homeAgreement.homeId)
      );
    });

    const shouldRedirectToSuccess =
      agreementSigned && isCurrentUserPrimaryOwner && currentProperty?.basicInfo?.propertyId;

    // If property missing or true, must show set password modal.
    if (user?.userMustSetPassword !== false) {
      return showModalAction(MODALS.LOGIN, {
        user,
        currentScreen: SCREENS.SET_PASSWORD_AFTER_AGREEMENT,
        closeButton: false,
        closable: false,
        onSucessfulLogin: async () => {
          history.push(
            `${STEPS_ROUTE_MAP.SUCCESS.path}/${flowId}${
              primaryOwnerName ? `?primaryOwnerName=${primaryOwnerName}` : ''
            }`
          );
        },
      });
    }

    trackFullStoryEvent({
      eventName: 'flow-completed',
      metadata: {
        ...fullStoryEventMetadata,
        stepEnum: 'success',
        stepDisplayName: 'Success',
      },
    });

    if (shouldRedirectToSuccess) {
      history.push(`${STEPS_ROUTE_MAP.SUCCESS.path}/${flowId}`);

      return;
    }

    history.push(
      `${STEPS_ROUTE_MAP.SUCCESS.path}/${flowId}${primaryOwnerName ? `?primaryOwnerName=${primaryOwnerName}` : ''}`
    );
  };

  const handleNext = () => {
    const currentStepIndex = findIndex(STEPS_CONFIGURATION, { name: currentStepBasedStepId?.name });

    if (!mappedSteps?.length) return;

    if (currentStepIndex < STEPS_CONFIGURATION.length - 1) {
      let stepIncrement = 1;

      while (currentStepIndex + stepIncrement < STEPS_CONFIGURATION.length) {
        const stepConfig = STEPS_CONFIGURATION[currentStepIndex + stepIncrement];
        const currentStep = find(mappedSteps, {
          stepName: stepConfig?.name,
        });

        if (currentStep?.isHidden) {
          stepIncrement += 1;
        } else {
          history.replace(getStepPath(currentStep, flowId));
          setAnimatedStep();
          return;
        }
      }

      if (currentStepIndex + stepIncrement === STEPS_CONFIGURATION.length) {
        navigateToSuccess();
        setAnimatedStep();
        return;
      }
    } else {
      navigateToSuccess();
    }
    setAnimatedStep();
    setSidebarTip(null);
  };

  const isLastStep = () => {
    const { currentMappedStep } = currentStepState;
    const filteredStepsConfig = mappedSteps.filter((ms) => {
      return !ms.isHidden;
    });
    const index = findIndex(filteredStepsConfig, { name: currentMappedStep.name });
    return index === filteredStepsConfig.length - 1;
  };

  const handleSave = async (values) => {
    try {
      // Added because errors are getting swallowed and it's almost impossible to debug without this
      setLoading(true);
      setIsErrorToastVisible(false);

      const { currentMappedStep } = currentStepState;

      try {
        await updateAgreementFlowStepAction(setupFlow.id, currentMappedStep.key, stepId, values);

        const isHOCommingFromSecondaryOwnershipSelection =
          step === 'ownership' && values?.agreementUser?.isPrimary === false;

        trackFullStoryEvent({
          eventName: 'flow-step-completed',
          metadata: fullStoryEventMetadata,
        });

        if (isHOCommingFromSecondaryOwnershipSelection) {
          setAnimatedStep();

          return navigateToSuccess({ primaryOwnerName: values?.agreementUser?.user?.firstName });
        }
      } catch (error) {
        handleErrorTracking();

        console.error(error);

        if (error?.[0]?.errorCode === 'INVALID_ADDRESS') {
          setIsErrorToastVisible(true);
          setErrorText(error?.[0]?.message);

          return;
        }
      }

      const currentStepIndex = findIndex(STEPS_CONFIGURATION, { name: currentMappedStep.name });

      if (currentStepIndex < STEPS_CONFIGURATION.length - 1) {
        let stepIncrement = 1;

        while (currentStepIndex + stepIncrement < STEPS_CONFIGURATION.length) {
          const stepConfig = STEPS_CONFIGURATION[currentStepIndex + stepIncrement];
          const currentStep = find(mappedSteps, {
            stepName: stepConfig.name,
          });

          if (currentStep.isHidden) {
            stepIncrement += 1;
          } else {
            history.push(getStepPath(currentStep, flowId));
            setAnimatedStep();
            return;
          }
        }

        if (currentStepIndex + stepIncrement === STEPS_CONFIGURATION.length) {
          navigateToSuccess({ primaryOwnerName: values?.agreementUser?.user?.firstName });
          setAnimatedStep();

          return;
        }
      } else {
        navigateToSuccess();
      }

      setAnimatedStep();
    } catch (e) {
      handleErrorTracking();

      history.push(PATHS.SUPPORT);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const setCurrentStep = async (_currentFlow, steps) => {
    if (stepId) {
      const currentStep = find(steps, { id: stepId });
      let currentStepFormDataResponse;
      try {
        currentStepFormDataResponse = await fetchAgreementFlowStepAction(flowId, currentStep.key, stepId);
      } catch (err) {
        handleErrorTracking();

        console.error(err);
      }

      setCurrentStepState({
        currentMappedStep: currentStep,
        currentStepFormData: currentStepFormDataResponse || {},
      });
    } else {
      setCurrentStepState({
        currentMappedStep: null,
        currentStepFormData: null,
      });
    }
  };

  const handleHomeOwnerComingFromEmail = (apiSteps) => {
    if (isComingFromEmailLink()) {
      const nextTodoInStepEnum = OrderedAgreementStepEnums.find((stepEnum) => {
        const currentStep = apiSteps.find((s) => s.stepName === stepEnum);

        return currentStep.isHidden === false && ['Created', 'Incomplete'].includes(currentStep?.status);
      });

      const nextStepId = apiSteps.find((s) => s.stepName === nextTodoInStepEnum)?.id;

      history.push(`${BASE_PATHS.AGREEMENT_FLOW}/${StepUrlPaths[nextTodoInStepEnum]}/${flowId}/${nextStepId}`);
    }
  };

  const handleCancelledAgreement = () => {
    history.push(
      `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}?agreementStatus=Cancelled&employeeAssignmentPhoneLink=${employeeAssignmentPhone.link}&employeeAssignmentPhoneDisplay=${employeeAssignmentPhone.display}`
    );
  };

  const isValidUrl = () => {
    if (!step && !flowId && !stepId) {
      return true;
    }

    // When navigating to the success page
    if (step === 'success' && flowId && !stepId) {
      return true;
    }

    const [stepBasedOnStepId] = mappedSteps.filter((ms) => ms.id === stepId);

    if (!stepBasedOnStepId) {
      return false;
    }

    const { key, id } = stepBasedOnStepId;

    if (key === step && id === stepId) {
      return true;
    }

    return false;
  };

  const handleToastClose = () => {
    setIsErrorToastVisible(false);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        if (!flowId) return;

        const setUpFlow = await fetchAgreementFlowByIdAction(flowId);

        if (isNil(setUpFlow) || isEmpty(setUpFlow)) {
          history.push(BASE_PATHS.ACCOUNTS); // Should it go to 404?
          return;
        }

        const [{ properties }, agreements] = await Promise.all([
          fetchAccountPropertiesAction(),
          fetchAccountAgreementsAction(),
        ]);

        setCurrentProperties(properties);

        const { steps: apiSteps, status } = setUpFlow;
        const signStep = apiSteps.find((apiStep) => apiStep.stepName === stepNames.Sign);

        // If Sign step is Completed or agreement is Cancelled, redirect to accounts page.
        if (status === StepStatus.Cancelled && step !== 'success') {
          handleCancelledAgreement();
        }

        if (signStep?.status === StepStatus.Completed && step !== 'success') {
          history.push(`${BASE_PATHS.HOMEOWNER_SETUP_FLOW}?agreementStatus=Completed`);
        }

        let currentAgreementObject;
        let property;

        let steps = apiSteps.map((apiStep) => {
          if (apiStep.dataType === AgreementFlowDataTypes.User) {
            return {
              ...apiStep,
              ...getStepByName(apiStep.stepName),
              data: user,
              rootDataType: AgreementFlowDataTypes.User,
              rootDataId: user.Id,
              rootData: user,
              properties,
              flowId: setUpFlow.id,
            };
          }

          if (apiStep.dataType === AgreementFlowDataTypes.Agreement) {
            currentAgreementObject = agreements?.find((a) => a?.agreement?.uniqueId === apiStep.dataId);

            return {
              ...apiStep,
              ...getStepByName(apiStep.stepName),
              data: user,
              rootDataType: AgreementFlowDataTypes.Agreement,
              rootDataId: currentAgreementObject?.uniqueId,
              rootData: currentAgreementObject,
              properties,
              flowId: setUpFlow.id,
            };
          }

          return {
            ...apiStep,
            flowId: setUpFlow.id,
          };
        });

        if (currentAgreementObject) {
          const homeIds = currentAgreementObject.homeAgreements?.map((h) => h.homeId);
          property = properties?.find((p) => p.units.find((u) => homeIds?.includes(u.basicInfo.unitId)));

          steps = steps.map((s) => {
            return { ...s, property };
          });
        }

        const settingsObject = await fetchSettingsAction();
        setSettings(settingsObject);

        steps = steps.map((stepItem) => ({
          ...stepItem,
          displayName: stepItem.stepName === 'Sign' ? 'Join' : stepItem.displayName,
        }));

        setSetupFlow(setUpFlow);
        setMappedSteps(steps);
        setCurrentAgreement(currentAgreementObject);
        handleHomeOwnerComingFromEmail(apiSteps);
        await setCurrentStep(setUpFlow, steps);
      } catch (err) {
        handleErrorTracking();

        console.error('AgreementFlow error: ', err);

        handleCancelledAgreement();
      } finally {
        setAnimateTextChange(true);
        setSidebarTip(null);
        setMakeGreen(false);
        setLoading(false);
      }
    })();
  }, [stepId, isUserLoggedIn]);

  useEffect(() => {
    if (mappedSteps?.length) {
      const currentStep = find(mappedSteps, { id: stepId });
      if (currentStep?.isHidden) {
        handleNext();
      }
    }
  }, [mappedSteps]);

  const { currentMappedStep, currentStepFormData } = currentStepState;

  if (!(loading || (stepId && !currentStepFormData))) {
    if (!isValidUrl()) {
      return <Redirect to={BASE_PATHS.ACCOUNTS} />;
    }
  }

  const getSidebarTitleText = () => {
    const stepName = currentStepState?.currentMappedStep?.stepName;

    if (sidebarTip) {
      return sidebarTip.title;
    }

    if (stepName) {
      return AGREEMENT_FLOW_STRINGS[`${stepName}.tips.title`];
    }

    return '';
  };

  const getSidebarDescriptions = () => {
    const stepName = currentStepState?.currentMappedStep?.stepName;

    if (sidebarTip) {
      return sidebarTip.descriptions;
    }

    if (stepName) {
      const description = AGREEMENT_FLOW_STRINGS[`${stepName}.tips.description`];

      if (stepName === stepNames.Sign) {
        const agreementHomeIds = currentAgreement?.homeAgreements?.map((homeAgreement) => homeAgreement.homeId);
        const anyAgreementHome = currentProperties?.find((property) => {
          return property.units.some((unit) => {
            return agreementHomeIds?.includes(unit.basicInfo.unitId);
          });
        });

        return description[anyAgreementHome?.address?.state] || description.california;
      }

      return description;
    }

    return [];
  };

  let employeeAssignment = currentMappedStep?.properties[0]?.units[0]?.employeeAssignments?.find(
    (ea) => ea.employeeAssignment.assignmentType === EmployeeAssignmentTypes.HomeownerGrowth
  );

  const defaultHomeownerGrowth = getDefaultHomeownerGrowth(currentMappedStep?.property?.basicInfo?.coverageRegionId);

  if (!employeeAssignment && !loading) {
    employeeAssignment = defaultHomeownerGrowth;
  } else if (!loading) {
    employeeAssignment.employee.phone = defaultHomeownerGrowth.employee.phone;
  }

  if (!employeeAssignmentPhone.display && !loading) {
    setEmployeeAssignmentPhone({
      link: `tel:${employeeAssignment?.employee?.phone?.link}`,
      display: employeeAssignment?.employee?.phone?.display,
    });
  }

  // useEfect for tracking FullStory events.
  useEffect(() => {
    if (!currentMappedStep || !setupFlow) return;

    const isCurrentStepCompleted = currentMappedStep.status === 'Completed';
    const fitstStepInFlow = setupFlow.steps?.find((s) => s.isHidden === false);
    const isCurrentStepTheFirstOne = currentMappedStep.id === fitstStepInFlow?.id;

    if (isCurrentStepTheFirstOne && !isCurrentStepCompleted) {
      return trackFullStoryEvent({
        eventName: 'flow-started',
        metadata: fullStoryEventMetadata,
      });
    }

    if (!isCurrentStepCompleted) {
      trackFullStoryEvent({
        eventName: 'flow-step-started',
        metadata: fullStoryEventMetadata,
      });
    }
  }, [currentMappedStep, setupFlow]);

  if (step === 'success') {
    const currentProperty = currentProperties?.find((property) => {
      return currentAgreement?.homeAgreements?.find((homeAgreement) =>
        property.units.some((unit) => unit.basicInfo.unitId === homeAgreement.homeId)
      );
    });

    if (currentProperty) {
      const propertyWithFarOffs = currentProperty.units.some((unit) => unit?.isFarOffMoveout);

      if (propertyWithFarOffs) {
        return history.push(`${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${currentProperty?.basicInfo.propertyId}/setup-flow`);
      }

      return (
        <Route
          key={STEPS_ROUTE_MAP.SUCCESS.path}
          path={STEPS_ROUTE_MAP.SUCCESS.path}
          render={() => (
            <Page
              headerMainProps={{
                fixed: true,
                navigationComponents: HeaderLinks,
              }}
              footer={false}
              header={!comeFromTheMobileApp}
            >
              <SuccessPage
                loading={loading}
                history={history}
                employeeAssignment={employeeAssignment}
                isPrimaryUser={isCurrentUserPrimaryOwner}
                propertyURL={`${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${currentProperty?.basicInfo.propertyId}`}
              />
            </Page>
          )}
        />
      );
    }
  }

  const progressItems = mappedSteps
    .filter((mappedStep) => !mappedStep.isHidden)
    .map(({ stepName, displayName, key, id, status }) => {
      return {
        active: stepName === currentMappedStep?.stepName,
        stepName,
        label: displayName,
        key,
        id,
        status,
      };
    });

  const onProgressStepClick = (mappedStep) => {
    const currentStepIndex = findIndex(STEPS_CONFIGURATION, { name: currentMappedStep.name });
    const clickedStepIndex = findIndex(STEPS_CONFIGURATION, { name: mappedStep.stepName });
    const isStepBeforeCurrentStep = clickedStepIndex < currentStepIndex;
    const isCurrentStepCompleted = currentMappedStep.status === StepStatus.Completed;

    if (isStepBeforeCurrentStep || isCurrentStepCompleted) {
      history.push(getStepPath(mappedStep, flowId));
    }
  };

  // Excluding success screen since it's layout is different and success screen has its own loader.
  const isFormLayoutLoading = (loading && step !== 'success') || (stepId && !currentStepFormData);

  return (
    <>
      <MetaNoIndex />
      <FlowLayoutV2
        location={location}
        loading={isFormLayoutLoading}
        sidebarProps={{
          step: animatedStep,
          makeGreen,
          animateTextChange: animateTextChange && !loading,
        }}
        headerMainProps={{ disableLogo: false }}
        navigationComponents={HeaderLinks}
        tip={{
          title: getSidebarTitleText(),
          description: getSidebarDescriptions(),
        }}
        steps={Object.values(STEPS_ROUTE_MAP)}
        stepProps={{
          onNext: handleNext,
          onSubmit: handleSave,
          setLoading,
          mappedSteps,
          currentMappedStep,
          isLastStep: currentMappedStep ? isLastStep() : false,
          currentStepFormData: stepId === currentMappedStep?.id ? currentStepFormData : null,
          setupFlow,
          history,
          setSidebarTip,
          setMakeGreen,
          settings,
          setAnimateTextChange,
          employeeAssignment,
        }}
        employeeAssignment={step !== 'success' ? employeeAssignment : null}
        showProgressBar={step !== 'success'}
        progressItems={progressItems}
        onlyActiveLabel={false}
        onProgressStepSelection={onProgressStepClick}
      />
      <ChatWidget
        tags={['Page: Agreement flow']}
        shouldShow={step !== 'success'}
        isEnabled={isEnabled}
        showOnNavbar={isMobile}
      />
      <Toast isVisible={isErrorToastVisible} onClose={handleToastClose} variant="danger">
        {errorText}
      </Toast>
    </>
  );
};

AgreementFlow.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  fetchAgreementFlowByIdAction: PropTypes.func.isRequired,
  fetchAgreementFlowStepAction: PropTypes.func.isRequired,
  fetchAccountPropertiesAction: PropTypes.func.isRequired,
  fetchAccountAgreementsAction: PropTypes.func.isRequired,
  updateAgreementFlowStepAction: PropTypes.func.isRequired,
  fetchSettingsAction: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(UserBasicInfo),
  showModalAction: PropTypes.func.isRequired,
};

AgreementFlow.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  user: selectUser(state),
});

const mapDispatchToProps = {
  fetchAgreementFlowByIdAction: fetchAgreementFlowById,
  fetchAgreementFlowStepAction: fetchAgreementFlowStep,
  fetchAccountPropertiesAction: fetchAccountProperties,
  fetchAccountAgreementsAction: fetchAccountAgreements,
  updateAgreementFlowStepAction: updateAgreementFlowStep,
  fetchSettingsAction: fetchSettings,
  showModalAction: showModal,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Analytics(({ match }) => ({ screen: match?.params?.step })),
  PageViewTracker
)(AgreementFlow);
